import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, KMCurve, FeaturedStat, TextBlock, ReferencesBlock, ComparisonTable} from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import CERVICAL from '../../components/StudyDesign/__study-design/cervical-a18'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-A18 - Clinical Trial Results | HCP`,
    keywords: `keynote a18, cervical cancer combination chemoradiotherapy`,
    description: `Health care professionals may find clinical trial results for combination chemoradiotherapy for FIGO 2014 stage 3-4A cervical cancer in KEYNOTE-A18.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/cervical-cancer-combination-chemoradiotherapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/cervical-cancer-combination-chemoradiotherapy/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠A18","description":"Progression⁠-⁠Free Survival (PFS): FIGO 2014 Stage III-IVA Exploratory Subgroup Analysis","trialDesign":"1,060 previously untreated patients with cervical cancer were evaluated in a phase 3 multicenter, randomized, double-blind, placebo-controlled trial"}`
    ]
}

//from /src/util/data/indications.js
// UPDATE THIS TO THE CORRECT INDICATION NUMBER
const indicationId = 37;
const jobCode = jobCodes[24].jobCode;


const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: '1L Combo Therapy Persistent, Recurrent, or Metastatic Cervical Cancer', url: '/efficacy/cervical-cancer-first-line-combination-therapy/' },
                { text: '2L Monotherapy Recurrent or Metastatic Cervical Cancer', url: '/efficacy/cervical-cancer-second-line-monotherapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', 
                url: '/safety/adverse-reactions/cervical-cancer-combination-chemoradiotherapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
                    ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;A18' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;A18 ' }, 
]

const pageReferences = [
    {
        label: "1.",
        text: "Opdivo [package insert]. Princeton, NJ: Bristol-Meyers Squibb Company; 2023."
    },
    {
        label: "2.",
        text: "Libtayo [package insert]. Tarrytown, NY: Regeneron Pharmaceuticals, Inc.; 2023. "
    },
    {
        label: "3.",
        text: "Jemperli [package insert]. Philadelphia, PA; GlaxoSmithKline LLC; 2023."
    },
    {
        label: "4.",
        text: "Zynyz [package insert]. Wilmington, DE: Incyte Corporation; 2023."
    },
    {
        label: "5.",
        text: "Loqtorzi [package insert]. Redwood City, CA: Coherus BioSciences, Inc.; 2023. "
    },
    {
        label: "6.",
        text: "Identifier: NCT04221945. Study of chemoradiotherapy with or without pembrolizumab (MK-3475) for the treatment of locally advanced cervical cancer (MK-3475-A18/KEYNOTE-A18/ENGOT-cx11/GOG-3047). ClinicalTrials.gov. Updated July 20, 2023. Accessed November 10, 2023. https://clinicaltrials.gov/study/NCT04221945"
    }
]

const kmA18Footnotes =[
    {
        label:"a.",
        text: "PFS assessed by investigator."
    },
    {
        label:"b.",
        text: "Based on the unstratified Cox proportional hazard model."
    }
]


const survivalFootnotes =[
    {
        label:"a.",
        text: "PFS assessed by investigator."
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                        <AnchorLinks items={anchorLinkModelData} />
                   </PageSection>
                   <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">The first and only anti&ndash;PD&#8288;-&#8288;1 indicated in combination with CRT for the treatment of FIGO 2014 Stage III-IVA cervical cancer<sup>1-5</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label} >
                            <PageSubSection title="Progression&#8288;-&#8288;Free Survival (PFS): FIGO 2014 Stage III-IVA Exploratory Subgroup Analysis ">
                            <TextBlock>
                                <p>KEYTRUDA, in combination with CRT, is indicated for the treatment of patients with FIGO 2014 Stage III-IVA cervical cancer</p>
                            </TextBlock>
                            <SecondaryCallout 
                                title='Clinically meaningful PFS<sup>a</sup> with KEYTRUDA + CRT vs placebo + CRT for patients with FIGO 2014 Stage III-IVA disease'
                            />
                            <KMCurve
                                    title="Kaplan-Meier Curve for PFS<sup>a</sup> in Patients with FIGO 2014 Stage III-IVA Cervical Cancer"
                                    hr="HR<sup>b</sup>=0.59 (95% CI: 0.43, 0.82)"
                                    footnotes={kmA18Footnotes} 
                                    definitions="CI = confidence interval; HR = hazard ratio."
                                    image={() => <StaticImage 
                                        src="../../assets/cervical-km-A18_v3.png" 
                                        placeholder="blurred" 
                                        alt="Kaplan-Meier Curve for PFS in Patients With FIGO 2014 Stage 3-4A Cervical Cancer" />}  
                                />

                            <ComparisonTable
                                    title="Progression-Free Survival (PFS)<sup>a</sup>"
                                    footnotes={survivalFootnotes}
                                    definitions="NR = not reached."
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA 200&nbsp;mg every 3 weeks and 400 mg every 6 weeks + CRT (n=293)',
                                                },
                                                {
                                                    legendTitle: 'Placebo + CRT (n=303)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Number of patients with event (%)',
                                                    dataPoint: '61',
                                                    dataSubPoint: '(21%)',
                                                },
                                                {
                                                    label: 'Number of patients with event (%)',
                                                    dataPoint: '94',
                                                    dataSubPoint: '(31%)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median PFS in months',
                                                    dataPoint: 'NR',
                                                    dataSubPoint: '(95% CI, NR–NR)',
                                                },
                                                {
                                                    label: 'Median PFS in months',
                                                    dataPoint: 'NR',
                                                    dataSubPoint: '(95% CI, 18.8–NR)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: '12-month PFS rate',
                                                    dataPoint: '81%',
                                                    dataSubPoint: '(95% CI, 75–85)',
                                                },
                                                {
                                                    label: '12-month PFS rate',
                                                    dataPoint: '70%',
                                                    dataSubPoint: '(95% CI, 64–76) ',
                                                },
                                            ],
                                        ]
                                    }
                                    
                                />

                                <SecondaryCallout
                                    title="41% reduction in the risk of disease progression or death with KEYTRUDA + CRT compared to placebo + CRT (HR<sup>b</sup>=0.59; 95% CI, 0.43–0.82)"
                                />

                                <TextBlock definitions="OS = overall survival.">
                                    <ul>
                                        <li>In an exploratory subgroup analysis for the 462 patients (44%) with FIGO 2014 Stage IB2–IIB node-positive disease, the PFS HR estimate was 0.91 (95% CI, 0.63–1.31) indicating that the statistically significant improvement in PFS in the overall population was primarily attributed to the results seen in the subgroup of patients with FIGO 2014 Stage III-IVA disease</li>
                                        <li>OS data were not mature at the time of PFS analysis, with 10% deaths in the overall population</li>
                                    </ul>
                                </TextBlock>
                            </PageSubSection>
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[1].label} >
                                <CERVICAL />
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>
                
                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>

                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;

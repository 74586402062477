import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';


const CERVICAL = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview 
            title="Study Overview" 
            description="1,060 previously untreated patients with cervical cancer were evaluated in a phase 3 multicenter, randomized, double-blind, placebo-controlled trial<sup>6</sup> " 
            
                data={[
                    [
                        {
                            title: 'Select Eligibility Criteria',
                            description: `
                                <ul>
                                    <li>Patients with FIGO 2014 Stage III-IVA (tumor involvement of the lower vagina with or without extension onto pelvic sidewall or hydronephrosis/non-functioning kidney or has spread to adjacent pelvic organs) with either node-positive or node-negative disease</li>
                                    <li>Patients with FIGO 2014 Stage IB2-IIB (tumor lesions >4&nbsp;cm or clinically visible lesions that have spread beyond the uterus but have not extended onto the pelvic wall or the lower third of vagina) with node-positive disease</li>
                                    <li>Patients had not previously received any definitive surgery, radiation, or systemic therapy for cervical cancer</li>
                                <ul>
                                <h5>Stratification Factors  </h5>
                                <ul>
                                    <li>Planned type of EBRT: IMRT or VMAT vs non-IMRT and non-VMAT </li>
                                    <li>Stage at screening of cervical cancer: FIGO 2014 Stage IB2&#8288;-&#8288IIB vs FIGO 2014 Stage III&#8288;-&#8288IVA  </li>
                                    <li>Planned total radiotherapy dose (EBRT + BT dose): <70 Gy vs ≥70 Gy (EQD2) </li>
                                <ul>

                            `,
                            type: 'box-gradient',
                            colspan: 2
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray',
                            colspan: 2
                        }
                    ],
                    [
                        {
                            title: 'Randomization <br>(1:1) <br>(N=1,060)<sup>c</sup>',
                            colspan: 2
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray',
                        },
                        {
                            type: 'arrow-gray',
                        }
                    ],
                ]} />

            <StudyDesignArms title="Study Arms" arms={[
            {
                title: "KEYTRUDA + CRT",
                data: [
                    [
                        {
                            title: `Treatment`,
                            description: `
                            KEYTRUDA 200&nbsp;mg IV Q3W (5 cycles) concurrent with cisplatin 40&nbsp;mg/m<sup>2</sup> IV weekly (5 cycles<sup>d</sup>) and radiotherapy (EBRT followed by BT)
                            `,
                            type: 'box-multi-color-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: `Followed by treatment with:`,
                            description: `
                                KEYTRUDA 400&nbsp;mg IV Q6W (15 cycles)
                                `,
                            type: 'box-multi-color-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: `Treatment duration
                                    `,
                            description: `
                                <p>Treatment continued until RECIST v1.1-defined progression of disease as determined by investigator or unacceptable toxicity.</p>
                            `,
                            type: 'box-multi-color-green'
                        }
                    ],
                ]
            },
            {
                title: "Placebo + CRT",
                data: [
                    [
                        {
                            title: `Treatment`,
                            description: `
                            Placebo IV Q3W (5 cycles) concurrent with cisplatin 40 mg/m<sup>2</sup> IV weekly (5 cycles<sup>d</sup>), and radiotherapy (EBRT followed by BT)
                            `,
                            type: 'box-multi-color-gray'
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: `Followed by treatment with:`,
                            description: `Placebo IV Q6W (15 cycles)`,
                            type: 'box-multi-color-gray'
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: `Treatment duration
                                    `,
                            description: `
                                <p>Treatment continued until RECIST v1.1-defined progression of disease as determined by investigator or unacceptable toxicity.</p>
                            `,
                            type: 'box-multi-color-gray'
                        }
                    ],
                ]
            }
        ]} />

        <StudyCopyBlock 
            description={`
                <p>Assessment of tumor status was performed every 12 weeks from completion of CRT for the first 2 years, followed by every 24 weeks in year 3, and then annually.</p>
            `} 
        />

        <StudyCopyBlock 
            title="End Points"
            description={` 
                <p>Major Efficacy Outcome Measures Included:</p>
                <ul>
                    <li>Progression-free survival (PFS): as assessed by investigator according to RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ, or histopathologic confirmation.</li>
                    <li>Overall survival (OS)</li>
                <ul>
            `} 
        />
    
        <StudyCopyBlock 
            title="Baseline Characteristics of Patients in FIGO 2014 Stage III-IVA Subgroup (n=596) "
            description={`
                <p>Median age (range): 52 years (22-87)</p>
                <ul>
                    <li>Age ≥65: 17%</li>
                </ul>

                <p>Race</p>
                <ul>
                    <li>White: 36% </li>
                    <li>Asian: 34% </li>
                    <li>Black: 1% </li>
                </ul> 

                <p>Ethnicity</p>
                <ul>
                    <li>Hispanic or Latino: 38% </li>
                </ul> 

                <p>ECOG PS</p>
                <ul>
                    <li>Score of 0: 68%</li>
                    <li>Score of 1: 32%</li>
                </ul>

                <p>CPS score</p>
                <ul>
                    <li>CPS ≥1: 93% </li>
                </ul>

                <p>Lymph node status</p>
                <ul>
                    <li>Positive pelvic and/or positive para-aortic lymph node(s): 70% </li>
                    <li>Neither positive pelvic nor para-aortic lymph node: 30% </li>
                </ul>

                <p>Histology</p>
                <ul>
                    <li>Squamous cell carcinoma: 83%</li>
                    <li>Non-squamous cell histology: 17%</li>
                </ul>

                <p>Radiotherapy type:</p> 
                <ul>
                    <li>IMRT or VMAT EBRT: 85%</li>
                </ul>

                <p>Median EQD2 dose (range): 87 Gy (7-114)</p>
            `} 
            footnotes={[
                {
                    label:'c.',
                    text: `596 patients had FIGO 2014 Stage III-IVA, 462 patients had FIGO 2014 Stage IB2-IIB, and 2 patients had FIGO 2014 Stage IVB disease.`
                },
                {
                    label:'d.',
                    text: `An optional 6th infusion could be administered per local practice.`
                },

            ]}
            definitions={`
            EBRT = external beam radiation therapy; ECOG PS = Eastern Cooperative Oncology Group performance status; EQD2 = equivalent dose; IMRT = intensity-modulated radiation therapy; PD&#8288;-&#8288;L1 = programmed death ligand 1; Q3W = every 3 weeks; Q6W = every 6 weeks; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1; VMAT = volumetric modulated arc therapy. 
            `} 
        />

    </ComponentWrapper>
);

export default CERVICAL;
